import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Auth from "./helpers/auth";
import { CampusCandidates } from "./layouts";
import { ROUTES } from "./constants/routeConstants";

// Code spiltting
const MainPage = React.lazy(() =>
  import("./pages/index").then(({ MainPage }) => ({
    default: MainPage,
  }))
);

const Info = React.lazy(() =>
  import("./pages/index").then(({ Info }) => ({
    default: Info,
  }))
);

const Summary = React.lazy(() =>
  import("./pages/index").then(({ Summary }) => ({
    default: Summary,
  }))
);

const Login = React.lazy(() =>
  import("./pages/index").then(({ Login }) => ({
    default: Login,
  }))
);

const Landing = React.lazy(() =>
  import("./pages/index").then(({ Landing }) => ({
    default: Landing,
  }))
);

const Position = React.lazy(() =>
  import("./pages/index").then(({ Position }) => ({
    default: Position,
  }))
);

const CreatePosition = React.lazy(() =>
  import("./pages/index").then(({ CreatePosition }) => ({
    default: CreatePosition,
  }))
);

const Candidate = React.lazy(() =>
  import("./pages/index").then(({ Candidate }) => ({
    default: Candidate,
  }))
);

const CreateCandidate = React.lazy(() =>
  import("./pages/index").then(({ CreateCandidate }) => ({
    default: CreateCandidate,
  }))
);

const Feedback = React.lazy(() =>
  import("./pages/index").then(({ Feedback }) => ({
    default: Feedback,
  }))
);

const Referral = React.lazy(() =>
  import("./pages/index").then(({ Referral }) => ({
    default: Referral,
  }))
);

const Question = React.lazy(() =>
  import("./pages/index").then(({ Question }) => ({
    default: Question,
  }))
);

const Response = React.lazy(() =>
  import("./pages/index").then(({ Response }) => ({
    default: Response,
  }))
);

const URL = React.lazy(() =>
  import("./pages/index").then(({ URL }) => ({
    default: URL,
  }))
);

const CreateQuestion = React.lazy(() =>
  import("./pages/index").then(({ CreateQuestion }) => ({
    default: CreateQuestion,
  }))
);

const SubjectiveResponse = React.lazy(() =>
  import("./pages/index").then(({ SubjectiveResponse }) => ({
    default: SubjectiveResponse,
  }))
);

const ObjectiveResponse = React.lazy(() =>
  import("./pages/index").then(({ ObjectiveResponse }) => ({
    default: ObjectiveResponse,
  }))
);

const ViewObjectiveResponse = React.lazy(() =>
  import("./pages/index").then(({ ViewObjectiveResponse }) => ({
    default: ViewObjectiveResponse,
  }))
);

const ViewSubjectiveResponse = React.lazy(() =>
  import("./pages/index").then(({ ViewSubjectiveResponse }) => ({
    default: ViewSubjectiveResponse,
  }))
);

const CreateURL = React.lazy(() =>
  import("./pages/index").then(({ CreateURL }) => ({
    default: CreateURL,
  }))
);

const ResumeViewerPage = React.lazy(() =>
  import("./pages/index").then(({ ResumeViewerPage }) => ({
    default: ResumeViewerPage,
  }))
);

// Fresher hiring module starts here

const Referrals = React.lazy(() =>
  import("./pages/index").then(({ Referrals }) => ({
    default: Referrals,
  }))
);

const ThankYouReferral = React.lazy(() =>
  import("./pages/index").then(({ ThankYouReferral }) => ({
    default: ThankYouReferral,
  }))
);

// Fresher hiring module ends here

// Campus hiring module starts here

const CampusHiringBasicCandidateProfile = React.lazy(() =>
  import("./pages/index").then(({ CandidateBasicProfile }) => ({
    default: CandidateBasicProfile,
  }))
);

const CampusHiringThankYou = React.lazy(() =>
  import("./pages/index").then(({ HiringThankYou }) => ({
    default: HiringThankYou,
  }))
);

const CampusHiring = React.lazy(() =>
  import("./pages/index").then(({ CampusHiring }) => ({
    default: CampusHiring,
  }))
);

const Campuses = React.lazy(() =>
  import("./pages/index").then(({ Campuses }) => ({
    default: Campuses,
  }))
);

const Candidates = React.lazy(() =>
  import("./pages/index").then(({ Candidates }) => ({
    default: Candidates,
  }))
);

const CreateCampus = React.lazy(() =>
  import("./pages/index").then(({ CreateCampus }) => ({
    default: CreateCampus,
  }))
);

const CandidatesList = React.lazy(() =>
  import("./pages/index").then(({ CandidatesList }) => ({
    default: CandidatesList,
  }))
);

const StageWiseView = React.lazy(() =>
  import("./pages/index").then(({ StageWiseView }) => ({
    default: StageWiseView,
  }))
);

const CandidatesListYear = React.lazy(() =>
  import("./pages/index").then(({ CandidatesListYear }) => ({
    default: CandidatesListYear,
  }))
);

const CandidatesListYearWise = React.lazy(() =>
  import("./pages/index").then(({ CandidatesListYearWise }) => ({
    default: CandidatesListYearWise,
  }))
);

const CandidateLiveStatus = React.lazy(() =>
  import("./pages/index").then(({ CandidateLiveStatus }) => ({
    default: CandidateLiveStatus,
  }))
);

// Campus hiring module ends here

const Router = () => {
  return (
    <Suspense fallback={<div className=""></div>}>
      <BrowserRouter>
        <Routes>
          <Route path={"/assessment/:id"} element={<MainPage />} />
          <Route path={"/"} element={<Info />} />
          <Route path={"/summary"} element={<Summary />} />
          <Route path="*" element={<Info />} />
          <Route path={"/reviewer/login"} element={<Login />} />
          <Route
            path={"/reviewer/landing"}
            element={
              <PrivateRoute>
                <Landing />
              </PrivateRoute>
            }
          />
          {/* Positions */}
          <Route
            path={"/reviewer/positions"}
            element={
              <PrivateRoute>
                <Position />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/create/position"}
            element={
              <PrivateRoute>
                <CreatePosition />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/edit/position"}
            element={
              <PrivateRoute>
                <CreatePosition />
              </PrivateRoute>
            }
          />
          {/* Candidates */}
          <Route
            path={"/reviewer/candidates"}
            element={
              <PrivateRoute>
                <Candidate />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/create/candidate"}
            element={
              <PrivateRoute>
                <CreateCandidate />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/edit/candidate"}
            element={
              <PrivateRoute>
                <CreateCandidate />
              </PrivateRoute>
            }
          />
          {/* Feedback */}
          <Route
            path={"/reviewer/feedbacks"}
            element={
              <PrivateRoute>
                <Feedback />
              </PrivateRoute>
            }
          />
          {/* Referral */}
          <Route
            path={"/reviewer/referrals"}
            element={
              <PrivateRoute>
                <Referral />
              </PrivateRoute>
            }
          />
          {/* Questions */}
          <Route
            path={"/reviewer/questions"}
            element={
              <PrivateRoute>
                <Question />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/create/question"}
            element={
              <PrivateRoute>
                <CreateQuestion />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/edit/question"}
            element={
              <PrivateRoute>
                <CreateQuestion />
              </PrivateRoute>
            }
          />
          {/* Response */}
          <Route
            path={"/reviewer/responses"}
            element={
              <PrivateRoute>
                <Response />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/responses/subjective"}
            element={
              <PrivateRoute>
                <SubjectiveResponse />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/responses/objective"}
            element={
              <PrivateRoute>
                <ObjectiveResponse />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/responses/view/objective"}
            element={
              <PrivateRoute>
                <ViewObjectiveResponse />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/responses/view/subjective"}
            element={
              <PrivateRoute>
                <ViewSubjectiveResponse />
              </PrivateRoute>
            }
          />

          {/* URL */}
          <Route
            path={"/reviewer/urls"}
            element={
              <PrivateRoute>
                <URL />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/create/url"}
            element={
              <PrivateRoute>
                <CreateURL />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/update/url"}
            element={
              <PrivateRoute>
                <CreateURL />
              </PrivateRoute>
            }
          />

          {/* Fresher hiring module */}
          {/* <Route path={"/referrals/2023"} element={<Referrals />} />
          <Route
            path={"/referrals/2023/thankYou"}
            element={<ThankYouReferral />}
          />
          <Route
            path={"/referrals/resumeViewer"}
            element={
              <PrivateRoute>
                <ResumeViewerPage />
              </PrivateRoute>
            }
          /> */}

          {/* Campus hiring routes starts here */}
          <Route
            path={"/campus-hiring/:url"}
            element={<CampusHiringBasicCandidateProfile />}
          />
          <Route
            path={"/campus-hiring/thank-you"}
            element={<CampusHiringThankYou />}
          />
          <Route
            path={"/reviewer/campus-hiring"}
            element={
              <PrivateRoute>
                <CampusHiring />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/campus-hiring/campuses"}
            element={
              <PrivateRoute>
                <Campuses />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/campus-hiring/candidates"}
            element={
              <PrivateRoute>
                <Candidates />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/campus-hiring/create/campus"}
            element={
              <PrivateRoute>
                <CreateCampus />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/campus-hiring/edit/campus"}
            element={
              <PrivateRoute>
                <CreateCampus />
              </PrivateRoute>
            }
          />
          {/* <Route
            path={"/reviewer/campus-hiring/campus/candidates/:id"}
            element={
              <PrivateRoute>
                <CandidatesList />
              </PrivateRoute>
            }
          /> */}
          <Route
            path={"/reviewer/campus-hiring/campus/candidates/:id"}
            element={
              <PrivateRoute>
                <CandidatesListYear />
              </PrivateRoute>
            }
          />
          <Route
            path={"/reviewer/campus-hiring/campus/candidates/:id/:year"}
            element={
              <PrivateRoute>
                <CandidatesListYearWise />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SELECTED_CANDIDATES_2024}
            element={
              <PrivateRoute>
                <CandidatesList />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SELECTED_CANDIDATES_2025}
            element={
              <PrivateRoute>
                <CandidatesList />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CAMPUS_CANDIDATES_LIVE_STATUS_2025}
            element={
              <PrivateRoute>
                <CandidateLiveStatus />
              </PrivateRoute>
            }
          />
          <Route
            path={"/campus-hiring/candidate-status/:url"}
            element={<StageWiseView />}
          />
          {/* Campus hiring routes ends here */}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

const PrivateRoute = ({ children }: any) => {
  const auth = Auth.isLoggedIn();
  return auth ? children : <Navigate to="/" replace={true} />;
};

export default Router;
