const Auth = {
  get(item: any) {
    const user = JSON.parse(localStorage.getItem("userDetails") || "");
    return user[item];
  },

  isLoggedIn() {
    let response;
    if (localStorage.getItem("userDetails")) {
      response = true;
    } else {
      response = false;
    }
    return response;
  },
};

export default Auth;
